import React, { useEffect } from "react";
import { Col, Row, Card, Form, Button } from '@themesberg/react-bootstrap';

import { useHistory } from "react-router-dom";
import { createCategory } from "../../services/category";
import showAlertMessage from "../../components/AlertMessage";
import { ProgressContext } from "../../context/ProgressContext";

export default () => {
  const navigate = useHistory();
  
  const { setIsLoading } = React.useContext(ProgressContext);

    const handleSubmit = (event) => {
      event.preventDefault();
      setIsLoading(true);
      const data = new FormData(event.currentTarget);

      var formData = new FormData();
      console.log(data);
      formData.append('image', data.get('image'));
      formData.append('name', data.get('name'));
      formData.append('description', data.get('description'));
      formData.append('name_am', data.get('name_am'));
      formData.append('description_am', data.get('description_am'));
      formData.append('isActive', data.get('isActive') === 'on' ? true : false);

      createCategory(formData).then((res) => {
        setIsLoading(false);
          if (res.success && res.data) {                 
              navigate.push("/product-categories");
              showAlertMessage(true,res.data.message);              
          } else {
            showAlertMessage(false,res.error);
          }
        }); 
    
    };

  return (
    <> <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        
    </div>
      <Row>
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Create Product Category Information Form</h5>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="name">
                <Form.Label>Category Name</Form.Label>
                <Form.Control required type="text" name="name" placeholder="Enter Category Name" />
              </Form.Group>
            </Col>

            <Col md={6} className="mb-3">
              <Form.Group id="name_am">
                <Form.Label>Category Name Amharic</Form.Label>
                <Form.Control required type="text" name="name_am" placeholder="Enter Category Name Amharic" />
              </Form.Group>
            </Col>
             
            <Col md={6} className="mb-3"> <br/>
            <Form.Group id="active">
              <Form.Check // prettier-ignore
                type="switch"
                name="isActive"
                id="isActive"
                label="Category is Active or Not ?"
              /> 
              </Form.Group>
            </Col>

            <Col md={6} className="mb-3">
              <Form.Group id="image">
                <Form.Label>Category Image</Form.Label>
                <Form.Control name="image" required type="file" placeholder="Choose Image" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
           
            
            <Col md={6} className="mb-3">
              <Form.Group id="description">
                <Form.Label>Category Description</Form.Label>
                <Form.Control name="description" as="textarea" required row={5} placeholder="Category Description" />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="description_am">
                <Form.Label>Category Description Amharic</Form.Label>
                <Form.Control name="description_am" as="textarea" required row={5} placeholder="Category Description Amharic" />
              </Form.Group>
            </Col>
             
          </Row> 
          <div className="mt-3">
          <Button variant="danger"  type="link" 
                                onClick={()=>navigate.push('/product-categories')}>Cancel</Button> &nbsp;&nbsp;
            <Button variant="primary" type="submit">Submit Form</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
      </Row>
    </>
  );
};
