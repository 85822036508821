import Swal from 'sweetalert2'

const showAlertMessage = (success,message) => {
    Swal.fire({
      title: success == true ? 'Success!' : 'Error!',
      text: message,
      icon: success == true ? 'success' :'error',
      confirmButtonText: success == true ? 'Ok' : 'Try Again'
    });
    }

export default showAlertMessage