
import React,{ useEffect,useState } from "react";
import dayjs from "dayjs";
import { Button, Card,Modal,Image, Col,Row,ListGroup } from '@themesberg/react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift } from '@fortawesome/free-solid-svg-icons';

import { useHistory } from "react-router-dom";
import { getSubPlans, subScribeOnlinePayment } from "../../services/subscription";
import showAlertMessage from "../../components/AlertMessage";
import { ProgressContext } from "../../context/ProgressContext";

export const SubscriptionWidget = (props) => {

    const { _id,package_amount,plan_name,
        price_etb,
        expiry_time } = props;
        
  
    return (
      <Card border="light" className="shadow-sm">
        <Card.Body>
          <Row className="d-block d-xl-flex align-items-center">
            <Col xl={5} xs={12} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
              <div className={`icon icon-shape icon-md icon-red rounded me-2 me-sm-0`}>
                <FontAwesomeIcon icon={faGift} />
              </div>
              <div className="d-sm-none">
                <h6>{plan_name}</h6>
              </div>
            </Col>
            <Col xs={12} xl={7} className="px-xl-0">
              <div className="d-none d-sm-block">
                    <h6>{plan_name}</h6>
              </div>              
                <div>{expiry_time} Months Subscription Plan</div> 
                <small>You Can Post {package_amount} Products</small>              
            </Col>
            
          </Row>
        </Card.Body>
        <Card.Footer className="border-bottom border-light d-flex justify-content-between">
        <h5 className="mb-0"><small>ETB {price_etb}</small></h5>
        <Button variant="secondary" size="sm" onClick={() => props.handleModal(_id)}>Subscribe</Button>
      </Card.Footer>
      </Card>
    );
  };
 
export default () => {

    
  const { setIsLoading } = React.useContext(ProgressContext);
    const navigate = useHistory();
    const [packageList, setPackageList] = useState([]);
    const [selectedPlan,setSelectedPlan] = useState('');
    const [paymentReturned,setPaymentReturned] = useState(false);
    const [checkOutUrl,setCheckOutUrl] = useState('');
    const [myPlan,setMyPlan] = useState({})
    const [showDefault, setShowDefault] = useState(false);
    const handleModal = (id) => {
        setSelectedPlan(id);
        setShowDefault(true);
    }
    const handleClose = () => {
        setShowDefault(false);
        setPaymentReturned(false);
        setCheckOutUrl("");
    }

    useEffect(() => {
        getSubPlans().then((res) => {
            console.log(res.data);
            if (res.success && res.data) {
                setPackageList(res.data.packages);
                setMyPlan(res.data.my_plan);
            }
        });
    },[]);

    const subScribePayment = (option)=>{
        setIsLoading(true);
        subScribeOnlinePayment({
            'subscription':selectedPlan
        },option).then((res)=>{
            setIsLoading(false);
            if(res.success && res.data) {
                setPaymentReturned(true);
                setCheckOutUrl(res.data.checkout_url);
                // window.open(res.data.checkout_url);
            }else{
                handleClose();
                showAlertMessage(false,res.error);
            }
            console.log(res.data);
        });
    }

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"></div>
            <Card border="light" className="shadow-sm">
            <Card.Header className="border-bottom border-light d-flex justify-content-between">
                <h5 className="mb-0">My Plan</h5>
                <p>
                    <small><b>Remaning Posts:</b>:{myPlan.product_amount}</small> <br/>
                    <small><b>ED</b>: {dayjs(myPlan.expiry_date).format("MMM DD,YYYY")}</small>
                </p>
            </Card.Header>
            <Card.Header className="border-bottom border-light d-flex justify-content-between">
                <h5 className="mb-0">Subscription Plans</h5>                 
            </Card.Header>
            </Card>
            <ListGroup className="list-group-flush list my--3">
                <Row>
                {packageList.map(tm => <Col xs={12} sm={4} xl={3} className="mb-4">
                        <SubscriptionWidget handleModal={handleModal} key={`sub-plans-${tm._id}`} {...tm} />
                    </Col>)}

                </Row>
            </ListGroup>
            <React.Fragment>

                <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
                    <Modal.Header>
                    <Modal.Title className="h6">Select Your Payment Options</Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={handleClose} />
                    </Modal.Header>
                    <Modal.Body className="text-center p-2">
                        { paymentReturned ? <iframe title="Complete Payment" height={600} src={checkOutUrl}></iframe> : <>
                        <Button variant="outline-secondary" color="dark" onClick={()=>subScribePayment('santim')} className="m-2" >
                            <Image src='santim.png' height={60} className="rounded-circle" />
                        </Button>

                        <Button variant="outline-secondary" color="dark" onClick={()=>subScribePayment('chapa')} className="m-2">
                            <Image src='chapa.png' height={60} className="rounded-circle" />
                        </Button>
                        </>}
                    
                    </Modal.Body>
                    <Modal.Footer>
                    {/* <Button variant="secondary" onClick={handleClose}>
                        I Got It
                    </Button> */}
                    <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>
                </React.Fragment>

        </>

    );
};
