import { axiosInstance} from "./apiHelper"; 


export const getCategories = async () => {
  try {
    const response = await axiosInstance.get(`/category/`);

    return { success: true, data: response.data };
  } catch (err) {
    if (err.response) {
      return { success: false, data: null, error: err.response.data.error };
    } else {
      return { success: false, data: null, error: err.error };
    }
  }
};

export const createCategory = async (formData) => {
    try {
      const response = await axiosInstance.post(`/category/add/`,formData,{
        headers: { 'Content-Type': 'multipart/form-data' }
      });
  
      return { success: true, data: response.data };
    } catch (err) {
      if (err.response) {
        return { success: false, data: null, error: err.response.data.error };
      } else {
        return { success: false, data: null, error: err.error };
      }
    }
  };

  export const getCategory = async (id) => {
    try {
      const response = await axiosInstance.get(`/category/${id}/`);
  
      return { success: true, data: response.data };
    } catch (err) {
      if (err.response) {
        return { success: false, data: null, error: err.response.data.error };
      } else {
        return { success: false, data: null, error: err.error };
      }
    }
  };

  
  export const getActiveCategory = async () => {
    try {
      const response = await axiosInstance.get(`/category/active`);
  
      return { success: true, data: response.data };
    } catch (err) {
      if (err.response) {
        return { success: false, data: null, error: err.response.data.error };
      } else {
        return { success: false, data: null, error: err.error };
      }
    }
  };

  export const editCategory = async (formData,id) => {
    try {
      const response = await axiosInstance.put(`/category/${id}`,formData,{
        headers: { 'Content-Type': 'multipart/form-data' }
      });
  
      return { success: true, data: response.data };
    } catch (err) {
      if (err.response) {
        return { success: false, data: null, error: err.response.data.error };
      } else {
        return { success: false, data: null, error: err.error };
      }
    }
  };
  

  
  export const deleteCategory = async (id) => {
    try {
      const response = await axiosInstance.delete(`/category/delete/${id}`);
  
      return { success: true, data: response.data };
    } catch (err) {
      if (err.response) {
        return { success: false, data: null, error: err.response.data.error };
      } else {
        return { success: false, data: null, error: err.error };
      }
    }
  };
  