import React, { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Col, Row, Card, Form, Button, Breadcrumb } from '@themesberg/react-bootstrap';
import { faHome, } from '@fortawesome/free-solid-svg-icons';
import AuthContext from "../../context/AuthContext";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { createFeaturedProduct } from "../../services/featured";
import { getActiveCategory } from "../../services/category";
import showAlertMessage from "../../components/AlertMessage";
import { ProgressContext } from "../../context/ProgressContext";

export default () => {
  const navigate = useHistory();
  const authCtx = useContext(AuthContext);

  const { setIsLoading } = React.useContext(ProgressContext);
  const [categories, setCategory] = React.useState([]);
  useEffect(() => {
    getActiveCategory().then((res) => {
      console.log(res.data);
      if (res.success && res.data) {
        setCategory(res.data.categories)
      }
    })
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const data = new FormData(event.currentTarget);
    console.log(data.get('image'));

    var formData = new FormData();
    formData.append('image', data.get('image'));
    formData.append('name', data.get('name'));
    formData.append('description', data.get('description'));
    formData.append('category', data.get('category'));
    formData.append('price', data.get('price'));
    formData.append('isActive', true);


    createFeaturedProduct(formData).then((res) => {
      setIsLoading(false);
      if (res.success && res.data) {
        navigate.push("/featured-products");
        showAlertMessage(true, res.data.message);
      } else {
        showAlertMessage(false,res.error);
      }
    });

  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Products</Breadcrumb.Item>
            <Breadcrumb.Item active>Create Featured Product</Breadcrumb.Item>
          </Breadcrumb>

        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          {/* <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
          </ButtonGroup> */}
        </div>
      </div>


      <Row>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h5 className="mb-4">Featured Product Information Form</h5>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group id="name">
                    <Form.Label>Product Name</Form.Label>
                    <Form.Control required type="text" name="name" placeholder="Enter Product name" />
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-3">
                  <Form.Group id="image">
                    <Form.Label>Product Image</Form.Label>
                    <Form.Control name="image" required type="file" placeholder="Choose Image" />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="category">
                    <Form.Label>Product Category</Form.Label>
                    <Form.Select defaultValue="0" name="category">
                      {
                        categories.map((category) => {
                          return (<option key={category._id} value={category._id}>{category.name}</option>)
                        })
                      }

                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="phone">
                    <Form.Label>Product Price in ETB</Form.Label>
                    <Form.Control name="price" required type="number" placeholder="Product price in ETB" />
                  </Form.Group>
                </Col> 

                <Col md={12} className="mb-3">
                  <Form.Group id="emal">
                    <Form.Label>Product Description</Form.Label>
                    <Form.Control name="description" as="textarea" required row={5} placeholder="Say Something about your product" />
                  </Form.Group>
                </Col>

              </Row>
              <div className="mt-3">
                <Button variant="danger" type="link"
                  onClick={() => navigate.push('/featured-products')}>Cancel</Button> &nbsp;&nbsp;
                <Button variant="primary" type="submit">Post Product</Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Row>
    </>
  );
};
