
import React, { useState } from "react";
import { Col, Row, Form, Button, Container } from '@themesberg/react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';

import { Routes } from "../../routes";

import {verifyOTP } from "../../services/auth";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import OtpInput from 'react-otp-input';
import showAlertMessage from "../../components/AlertMessage";

export default () => {

  const authCtx = useContext(AuthContext);
  const userid = useParams();
  const navigate = useHistory(); 

  const [{ otp, numInputs, separator, minLength, maxLength, placeholder, inputType }, setConfig] = React.useState({
    otp: '',
    numInputs: 4,
    separator: '-',
    minLength: 0,
    maxLength: 40,
    placeholder: '',
    inputType: 'number',
  });

  const handleOTPChange = (otp) => {
    setConfig((prevConfig) => ({ ...prevConfig, otp }));
  };

  const clearOtp = () => {
    setConfig((prevConfig) => ({ ...prevConfig, otp: '' }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    verifyOTP({otp:otp, user_id:userid.id}).then((res) => {
      if (res.success && res.data) {
        console.log(res.data);
        authCtx.login(
          res.data.token,
        );
        showAlertMessage(true, res.data.message);
        navigate.push(`${Routes.CreateProduct.path}`);
      } else {
        showAlertMessage(false,res.error);
      }
    });
  };

   

  
  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container> 
          <Row className="justify-content-center form-bg-image">
            {/* # style={{ backgroundImage: `url(${BgImage})` }} */}
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-2 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Verify Your click art account</h3>
                </div>
                <Form className="mt-4 text-center text-md-center mb-4" onSubmit={handleSubmit}>
                <div style={{display:'flex', justifyContent: "space-between",alignItems:"center"}}>
                <OtpInput
                inputStyle="inputStyle"
                numInputs={numInputs}
                onChange={handleOTPChange}
                renderSeparator={<span>{separator}</span>}
                value={otp}
                placeholder={placeholder}
                inputType={inputType}
                renderInput={(props) => <input {...props} />}
                shouldAutoFocus
              />
                </div>
                
                <div className="row mt-4">
                  <div className="col-6"><Button variant="warning" disabled={otp.trim() === ''} onClick={clearOtp}className="w-100">
                    Clear
                  </Button></div>
                  <div className="col-6"><Button variant="primary" type="submit" disabled={otp.length < numInputs} className="w-100">
                    Verify OTP
                  </Button></div>
                </div>
                </Form>

                               
                 
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
