
import React, { useState } from "react";

import { Badge, Button, Card, Table } from '@themesberg/react-bootstrap';
import { useEffect } from "react";

import { useHistory } from "react-router-dom";
import { deleteOrder, getOrders } from "../../services/orders";

import { AlertContext } from "../../context/AlertContext";
import { useContext } from "react";
import { baseUrlFile } from "../../services/apiHelper";
import showAlertMessage from "../../components/AlertMessage";

const TableRow = (props) => {

    const { _id, customer_name,phoneNumber,merchant,product } = props;

    return (
        <tr>
            <td>
                <Card.Link href="#" className="text-primary fw-bold">{_id}</Card.Link>
            </td>
            <td className="fw-bold">
                {customer_name}
            </td>
            <td className="fw-bold">
                {phoneNumber}
            </td>
            <td className="fw-bold">
                { product != null ? product.name :<Badge  bg="warning" text="white" className="me-1">Deleted Item</Badge >}
            </td>

            <td>
                {merchant.phoneNumber}
            </td>
            <td>
            { product != null ? <img className="w-10" width={50} src={baseUrlFile + product.imageUrl} alt={product.name} /> :<Badge  bg="warning" text="white" className="me-1">Deleted Item</Badge >}
            </td>
        </tr>
    );
};

export default () => {
    const { showAlertMessage } = useContext(AlertContext);
    const navigate = useHistory();
    const [orderList, setOrders] = useState([]); 

    useEffect(() => {
        getOrders().then((res) => {
            console.log(res.data);
            if (res.success && res.data) {
                setOrders(res.data.orders);
            }
        });
    },[]);


    const handleDelete = (id) => {
       if(window.confirm('Are you sure you want to delete this Order') == true){
        deleteOrder(id).then((res) => {
            if (res.success && res.data) {
                showAlertMessage(true, "Order Data Deleted Successfully");
                setOrders(
                    orderList.filter((value) => {
                      return value._id !== id;
                    })
                  );
            } else {
                showAlertMessage(false,res.error);
            }
        });
       } 

    };
 


    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            
            
            </div>


            <Card border="light" className="shadow-sm mb-4">
                <Card.Body className="pb-0">
                    <Table responsive className="table-centered table-nowrap rounded mb-0">
                        <thead className="thead-light">
                            <tr>
                                <th className="border-0">#</th>
                                <th className="border-0">Customer Name</th>
                                <th className="border-0">Phone Number</th>
                                <th className="border-0">Item Name</th>
                                <th className="border-0">Merchant Phone</th>
                                <th className="border-0">Image</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderList.map(pt => <TableRow key={`page-traffic-${pt._id}`} {...pt} navigate={navigate} handleDelete={handleDelete} />)}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

        </>

    );
};
