
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import { Routes } from "../../routes";
import { login } from "../../services/auth";
import { useContext } from "react";
import { AlertContext } from "../../context/AlertContext";
import showAlertMessage from "../../components/AlertMessage";

export default () => {

  const navigate = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if(data.get('phoneNumber')){

      login(data.get('phoneNumber')).then((res) => {

        if (res.success && res.data) {             
            navigate.push(`/verify-otp/${res.data.data.user_id}`);
            showAlertMessage(true, res.data.message);  
          
        } else {
          showAlertMessage(false,res.error);
        }
      }); 
  
    }else{
      showAlertMessage(false, "Please fill out all required input fields");
    }
  };


  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          {/* <p className="text-center">
            <Card.Link as={Link} to={Routes.DashboardOverview.path} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
            </Card.Link>
          </p> */}
          <Row className="justify-content-center form-bg-image" >
          {/* style={{ backgroundImage: `url(${BgImage})` }} */}
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-2 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in to your click art</h3>
                </div>
                <Form className="mt-4" onSubmit={handleSubmit}>
                  <Form.Group id="phoneNumber" className="mb-4">
                    <Form.Label>Your Phone Number</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faPhoneAlt} />
                      </InputGroup.Text>
                      <Form.Control name="phoneNumber" autoFocus required type="text"
                       placeholder="0912345678" />
                    </InputGroup>
                  </Form.Group>
                   
                  <Button variant="primary" type="submit"  className="w-100">
                    Sign in
                  </Button>
                </Form>

                               
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                      {` Register as a supplier `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
