
import React, { useContext, useState } from "react";
import SimpleBar from 'simplebar-react';
import { useHistory, useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faChartPie, faCog, faFileAlt, faHandHoldingUsd, 
  faSignOutAlt, faTable, faTimes,faCalendarTimes, faUser, faGraduationCap, faBriefcaseMedical,
   faCodeBranch, faTree, faAd, faSubscript, faTruck, faGifts, faGift, faBusinessTime} from "@fortawesome/free-solid-svg-icons";


import { Nav, Badge, Image, Button, Dropdown, Accordion, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';


import { Routes } from "../routes";

import ProfilePicture from "../assets/img/profile.jpg";
import useAuth from "../hook/useAuth";
import AuthContext from "../context/AuthContext";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const { name,role } = useAuth(AuthContext);
  const navigate = useHistory();
  const authCtx = React.useContext(AuthContext);


  const handleLogout = () => {

    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to logout?") === true) {
      authCtx.logout();
      navigate.push("/sign-in")

    }
  }

  const onCollapse = () => setShow(!show);

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashboardOverview.path}>
          {/* <Image src='logo-white.png' style={{height:'45px'}} className="navbar-brand-light" /> */}
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />
                </div>
                <div className="d-block">
                  <h6>Hi, { name } </h6>
                  <Button as={Link} variant="secondary" 
                  onClick={handleLogout}
                  size="xs" to={Routes.Signin.path} className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <NavItem title="Overview" link={Routes.DashboardOverview.path} icon={faChartPie} />

              <NavItem title="Products" link={Routes.ProductList.path} icon={faCalendarTimes} />
              
              <NavItem title="Orders" link={Routes.ItemOrders.path} icon={faBriefcaseMedical} />
              <NavItem title="My Subscription Plans" link={Routes.Subscription.path} icon={faGift} />
              <NavItem title="My Deliveries" link={Routes.MDeliveries.path} icon={faTruck} />
              {
                role == 'ROLE ADMIN' ?
                <>
                    <NavItem title="Featured Products" link={Routes.FeaturedProducts.path} icon={faAd} />
                    <NavItem title="Delivery" link={Routes.DeliveryList.path} icon={faTruck} />
                    <NavItem title="Categories" link={Routes.CategoryList.path} icon={faBoxOpen} />
                    <NavItem title="Merchants" link={Routes.ProductList.path} icon={faBusinessTime} />
                    <Dropdown.Divider className="my-3 border-indigo" /> 
                    <NavItem title="Users" icon={faUser} link={Routes.Settings.path} />
                    <NavItem title="Subscription Plans" icon={faGifts} link={Routes.SubPlanList.path} />
                      <Dropdown.Divider className="my-3 border-indigo" /> 
                    <Button as={Link} variant="secondary" 
                        onClick={handleLogout}
                        size="xs" to={Routes.Signin.path} className="text-dark">
                        <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                    </Button>
                </>:<></>
              }
              
              {/* <NavItem title="Sign Out" icon={faSignOutAlt} onClick={handleLogout} /> */}
              {/* <NavItem title="Settings" icon={faCog} link={Routes.Settings.path} /> */}
              
              {/* <CollapsableNavItem eventKey="tables/" title="Tables" icon={faTable}>
                <NavItem title="Bootstrap Table" link={Routes.BootstrapTables.path} />
              </CollapsableNavItem> */}

              {/* <CollapsableNavItem eventKey="examples/" title="Page Examples" icon={faFileAlt}>
                <NavItem title="Sign In" link={Routes.Signin.path} />
                <NavItem title="Sign Up" link={Routes.Signup.path} />
                <NavItem title="Forgot password" link={Routes.ForgotPassword.path} />
                <NavItem title="Reset password" link={Routes.ResetPassword.path} />
                <NavItem title="Lock" link={Routes.Lock.path} />
                <NavItem title="404 Not Found" link={Routes.NotFound.path} />
                <NavItem title="500 Server Error" link={Routes.ServerError.path} />
              </CollapsableNavItem> */}


              
              {/* <CollapsableNavItem eventKey="components/" title="Components" icon={faBoxOpen}>
                <NavItem title="Accordion" link={Routes.Accordions.path} />
                <NavItem title="Alerts" link={Routes.Alerts.path} />
                <NavItem title="Badges" link={Routes.Badges.path} />
                <NavItem title="Breadcrumbs" link={Routes.Breadcrumbs.path} />
                <NavItem title="Buttons" link={Routes.Buttons.path} />
                <NavItem title="Forms" link={Routes.Forms.path} />
                <NavItem title="Modals" link={Routes.Modals.path} />
                <NavItem title="Navbars" link={Routes.Navbars.path} />
                <NavItem title="Navs" link={Routes.Navs.path} />
                <NavItem title="Pagination" link={Routes.Pagination.path} />
                <NavItem title="Popovers" link={Routes.Popovers.path} />
                <NavItem title="Progress" link={Routes.Progress.path} />
                <NavItem title="Tables" link={Routes.Tables.path} />
                <NavItem title="Tabs" link={Routes.Tabs.path} />
                <NavItem title="Toasts" link={Routes.Toasts.path} />
                <NavItem title="Tooltips" link={Routes.Tooltips.path} />
              </CollapsableNavItem> */}


            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
