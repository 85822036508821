import React,{ useContext } from "react";

import { Col, Row, Card, Form, Button } from '@themesberg/react-bootstrap';
import AuthContext from "../../context/AuthContext";
import { useHistory } from "react-router-dom";
import { createPackage } from "../../services/subpackage";
import showAlertMessage from "../../components/AlertMessage";
import { ProgressContext } from "../../context/ProgressContext";

export default () => {
  const navigate = useHistory();
  const authCtx = useContext(AuthContext);

  const { setIsLoading } = React.useContext(ProgressContext);
    
    const handleSubmit = (event) => {
      event.preventDefault();
      setIsLoading(true);
      const formData = new FormData(event.currentTarget);
      
      // formData.append('isActive', data.get('isActive') === 'on' ? true : false);
      
      createPackage({
        'package_amount':formData.get('package_amount'),
        "plan_name":formData.get('plan_name'),
        'price_etb':formData.get('price_etb'),
        'expiry_time':formData.get('expiry_time'),
        'isActive':formData.get('isActive') === 'on' ? true : false
      }).then((res) => {setIsLoading(false);
          if (res.success && res.data) {                 
              navigate.push("/sub-packages");
              showAlertMessage(true,res.data.message);              
          } else {
            showAlertMessage(false,res.error);
          }
        }); 
    
    };

  return (
    <> 
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        
      </div>


      <Row>
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Subscription Plan / Package Information Form</h5>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="name">
                <Form.Label>Subscription Plan Name</Form.Label>
                <Form.Control required type="text" name="plan_name" placeholder="Enter Plan Name" />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Group id="plan_type">
                <Form.Label>Subscription Plan Type</Form.Label>
                <Form.Select name="plan_type">
                  {
                    [
                      {value:"TRIAL",label:"Trial Plan"},
                      {value:"PREMIUM",label:"Premium Plan"}
                    ].map((item) =>{
                      return (<option key={item.value} value={item.value}>{item.label}</option>)
                    })
                  }                      
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="name">
                <Form.Label>Allowed Product Amount</Form.Label>
                <Form.Control required type="number" name="package_amount" placeholder="Enter Plan Amount" />
              </Form.Group>
            </Col> 
            <Col md={6} className="mb-3">
              <Form.Group id="phone">
                <Form.Label>Plan Price in ETB</Form.Label>
                <Form.Control name="price_etb" required type="number" placeholder="Enter Price in ETB" />
              </Form.Group>
            </Col>
          
            
          <Col md={6} className="mb-3">
              <Form.Group id="name">
                <Form.Label>Expiry time in Months</Form.Label>
                <Form.Control required type="number" name="expiry_time" placeholder="Enter Plan Expirey Time" />
              </Form.Group>
            </Col>
                  
            <Col md={6} className="mb-3"><br></br>
            <Form.Group id="active">
              <Form.Check // prettier-ignore
                type="switch"
                name="isActive"
                id="isActive"
                label="plan is Active or Not ?"
              /> 
              </Form.Group>
            </Col>
            
          </Row>
           
          <div className="mt-3">
          <Button variant="danger"  type="link" onClick={()=>navigate.push('/sub-packages')}>Cancel</Button> &nbsp;&nbsp;
            <Button variant="primary" type="submit">Create Subscription Plan</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
      </Row>
    </>
  );
};
