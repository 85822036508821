
import React,{ useEffect,useState } from "react";

import { Button, Card, Table } from '@themesberg/react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEdit, faPlus,faTrash
} from '@fortawesome/free-solid-svg-icons';

import { Routes } from "../../routes";
import { useHistory } from "react-router-dom";
import { AlertContext } from "../../context/AlertContext";
import { useContext } from "react";
import { getPackages,deletePackage } from "../../services/subpackage";
import showAlertMessage from "../../components/AlertMessage";
import { ProgressContext } from "../../context/ProgressContext";

const TableRow = (props) => {

    const { _id,plan_name, package_amount,plan_type, price_etb,isActive, expiry_time } = props;

    return (
        <tr>
            <td>
                <Card.Link href="#" className="text-primary fw-bold">{_id}</Card.Link>
            </td>
            <td>
                {plan_name}
            </td>
            <td>{plan_type}</td>
            <td className="fw-bold">
                {package_amount}
            </td>
            <td>ETB {price_etb}</td>
            <td>{expiry_time}</td>
            <td>
                {isActive ? "Yes":"No"}
            </td>
            <td>
            <Button variant="warning" size="sm" className="me-2" 
                    onClick={()=>props.navigate.push(`/update-package/${_id}`)} > 
                    <FontAwesomeIcon icon={faEdit}/>
                </Button>
                <Button variant="danger" size="sm"
                 onClick={()=>props.handleDelete(_id)}> <FontAwesomeIcon icon={faTrash}/></Button>
            </td>
        </tr>
    );
};

export default () => {
    const navigate = useHistory();
    const [packageList, setPackageList] = useState([]);

    const { setIsLoading } = React.useContext(ProgressContext);
    useEffect(() => {
        getPackages().then((res) => {
            console.log(res.data);
            if (res.success && res.data) {
                setPackageList(res.data);
            }
        });
    },[]);


    const handleDelete = (id) => {
        if(window.confirm('Are you sure you want to delete this Subscription Plan') == true){
            setIsLoading(true);
         deletePackage(id).then((res) => {
            setIsLoading(false);
             if (res.success && res.data) {
                 showAlertMessage(true,"Subscription Plan/Package Data Deleted Successfully");
                 setPackageList(
                    packageList.filter((value) => {
                       return value._id !== id;
                     })
                   );
             } else {
                showAlertMessage(false,res.error);
             }
         });
        } 
 
     };


    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <Button onClick={()=>navigate.push(`${Routes.CreateSubPlan.path}`)}> 
                    <FontAwesomeIcon icon={faPlus}  className="me-2" />
                    <span>Add New</span>
                </Button>
                    
            </div>


            <Card border="light" className="shadow-sm mb-4">
                <Card.Body className="pb-0">
                    <Table responsive className="table-centered table-nowrap rounded mb-0">
                        <thead className="thead-light">
                            <tr>
                                <th className="border-0">#</th>
                                <th className="border-0">Plan Name</th>
                                <th className="border-0">Plan Type</th>
                                <th className="border-0">Package Amount</th>
                                <th className="border-0">Price</th>
                                <th className="border-0">Expiry Time</th>
                                <th className="border-0">Is Actove</th>
                                <th className="border-0">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {packageList.map(pt => <TableRow key={`page-traffic-${pt._id}`} {...pt}  navigate={navigate} handleDelete={handleDelete}  />)}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            {/* </div> */}
        </>

    );
};
