
export const Routes = {
    // pages

    DashboardOverview: { path: "/" },
    Transactions: { path: "/transactions" },
    Settings: { path: "/settings" },
    BootstrapTables: { path: "/tables/bootstrap-tables" },
    Billing: { path: "/examples/billing" },
    Invoice: { path: "/examples/invoice" },
    Signin: { path: "/sign-in" },
    Signup: { path: "/sign-up" },
    Verify:{path:"/verify-otp/:id"},
    ForgotPassword: { path: "/examples/forgot-password" },
    ResetPassword: { path: "/examples/reset-password" },
    Lock: { path: "/examples/lock" },
    NotFound: { path: "/examples/404" },
    ServerError: { path: "/examples/500" },

    // docs
    DocsOverview: { path: "/documentation/overview" },
    DocsDownload: { path: "/documentation/download" },
    DocsQuickStart: { path: "/documentation/quick-start" },
    DocsLicense: { path: "/documentation/license" },
    DocsFolderStructure: { path: "/documentation/folder-structure" },
    DocsBuild: { path: "/documentation/build-tools" },
    DocsChangelog: { path: "/documentation/changelog" },

    // components
    Accordions: { path: "/components/accordions" },
    Alerts: { path: "/components/alerts" },
    Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    Breadcrumbs: { path: "/components/breadcrumbs" },
    Buttons: { path: "/components/buttons" },
    Forms: { path: "/components/forms" },
    Modals: { path: "/components/modals" },
    Navs: { path: "/components/navs" },
    Navbars: { path: "/components/navbars" },
    Pagination: { path: "/components/pagination" },
    Popovers: { path: "/components/popovers" },
    Progress: { path: "/components/progress" },
    Tables: { path: "/components/tables" },
    Tabs: { path: "/components/tabs" },
    Tooltips: { path: "/components/tooltips" },
    Toasts: { path: "/components/toasts" },
    WidgetsComponent: { path: "/components/widgets"},
    ProductList:{ path:'/products'},
    SubPlanList:{ path:'/sub-packages'},
    CreateSubPlan:{path:'/create-package'},
    UpdateSubPlan:{path:'/update-package/:id'},
    CreateProduct:{ path:'/product-create'},
    UpdateProduct:{ path:'/product-update/:id'},
    CategoryList:{ path:'/product-categories'},
    CreateCategory:{ path:'/create-category'},
    UpdateCategory:{ path:'/update-category/:id'},
    FeaturedProducts:{ path:'/featured-products'},
    CreateFeaturedProducts:{ path:'/create-featured-products'},
    UpdateFeaturedProducts:{ path:'/update-featured-products/:id'},
    ItemOrders:{ path:'/get-orders'},
    Subscription:{path:'/subscription'},

    DeliveryList:{ path:'/deliveries'},
    MDeliveries:{path:'/delivery-list'},
    CreateDelivery:{path:'/create-delivery'},
    UpdateDelivery:{path:'/update-delivery/:id'},
};