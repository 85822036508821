import React,{ useContext } from "react";

import { Col, Row, Card, Form, Button } from '@themesberg/react-bootstrap';
import AuthContext from "../../context/AuthContext";
import { useHistory } from "react-router-dom";
import { createDelivery } from "../../services/delivery";
import showAlertMessage from "../../components/AlertMessage";
import { ProgressContext } from "../../context/ProgressContext";

export default () => {
  const navigate = useHistory();
  const authCtx = useContext(AuthContext);

  const { setIsLoading } = React.useContext(ProgressContext);
    
    const handleSubmit = (event) => {
      event.preventDefault();
      setIsLoading(true);
      const formData = new FormData(event.currentTarget);
      
      
      createDelivery({
        'fullName':formData.get('fullName'),
        "phoneNumber":formData.get('phoneNumber'),
        'deliveryType':formData.get('deliveryType')
      }).then((res) => {
        setIsLoading(false);
          if (res.success && res.data) {                 
              navigate.push("/deliveries");
              showAlertMessage(true, res.data.message);              
          } else {
            showAlertMessage(false,res.error);
          }
        }); 
    
    };

  return (
    <> 
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        
      </div>


      <Row>
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Delivery Vehicle Information Form</h5>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="name">
                <Form.Label>Driver Name</Form.Label>
                <Form.Control required type="text" name="fullName" placeholder="Enter Driver's Name" />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Group id="deliveryType">
                <Form.Label>Vehicle / Transport Type</Form.Label>
                <Form.Select name="deliveryType">
                  {
                    [
                      {value:"Bicycle",label:"Bicycle"},
                      {value:"Motor Cycle",label:"Motor Cycle"},
                      {value:"Car",label:"Car"}
                    ].map((item) =>{
                      return (<option key={item.value} value={item.value}>{item.label}</option>)
                    })
                  }                      
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="name">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control required type="number" name="phoneNumber" placeholder="Enter Phone Number" />
              </Form.Group>
            </Col> 
 
            
          </Row>
           
          <div className="mt-3">
          <Button variant="danger"  type="link" onClick={()=>navigate.push('/deliveries')}>Cancel</Button> &nbsp;&nbsp;
            <Button variant="primary" type="submit">Create Delivery</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
      </Row>
    </>
  );
};
