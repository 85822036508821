import { axiosInstance} from "./apiHelper"; 
 
export const getReport = async () => {
  try {
    const response = await axiosInstance.get(`/report/`);

    return { success: true, data: response.data };
  } catch (err) {
    if (err.response) {
      return { success: false, data: null, error: err.response.data.error };
    } else {
      return { success: false, data: null, error: err.error };
    }
  }
}; 
