import { axiosInstance} from "./apiHelper"; 
 
export const getSubPlans = async () => {
  try {
    const response = await axiosInstance.get(`/subscription/sub-package/`);

    return { success: true, data: response.data };
  } catch (err) {
    if (err.response) {
      return { success: false, data: null, error: err.response.data.error };
    } else {
      return { success: false, data: null, error: err.error };
    }
  }
}; 

export const subScribeOnlinePayment = async (formData,option) => {
  try {
    const response = await axiosInstance.post(`/${option}-payment/init/`,formData);

    return { success: true, data: response.data };
  } catch (err) {
    if (err.response) {
      return { success: false, data: null, error: err.response.data.error };
    } else {
      return { success: false, data: null, error: err.error };
    }
  }
};


// export const getPackage = async (id) => {
//   try {
//     const response = await axiosInstance.get(`/sub-package/${id}/`);

//     return { success: true, data: response.data };
//   } catch (err) {
//     if (err.response) {
//       return { success: false, data: null, error: err.response.data.error };
//     } else {
//       return { success: false, data: null, error: err.error };
//     }
//   }
// };

// export const editPackage = async (formData,id) => {
//   try {
//     const response = await axiosInstance.put(`/sub-package/${id}/`,formData);

//     return { success: true, data: response.data };
//   } catch (err) {
//     if (err.response) {
//       return { success: false, data: null, error: err.response.data.error };
//     } else {
//       return { success: false, data: null, error: err.error };
//     }
//   }
// };


// export const deletePackage = async (id) => {
//   try {
//     const response = await axiosInstance.delete(`/sub-package/delete/${id}/`);

//     return { success: true, data: response.data };
//   } catch (err) {
//     if (err.response) {
//       return { success: false, data: null, error: err.response.data.error };
//     } else {
//       return { success: false, data: null, error: err.error };
//     }
//   }
// };