import { axiosInstance} from "./apiHelper";

 
export const login = async (phoneNumber) => {
  try {
    const response = await axiosInstance.post("/auth/login/", JSON.stringify({
      phoneNumber: phoneNumber
    }));

    return { success: true, data: response.data };
  } catch (err) {
    if (err.response) {
      console.log(err.response.data);
      return { success: false, data: null, error: err.response.data.error };
    } else {
      console.log(`Error: ${err.error}`);
      return { success: false, data: null, error: err.error };
    }
  }
};


export const createAccount = async (formData) => {
  try {
    const response = await axiosInstance.post("/auth/register/", formData);

    return { success: true, data: response.data };
  } catch (err) {
    console.log(err.response);
    if (err.response) {
      return { success: false, data: null, error: err.response.data.error ?? err.response.data.detail};
    } else {
      console.log(`Error: ${err.error}`);
      return { success: false, data: null, error: err.error };
    }
  }
};

export const updateProfile = async (formData) => {
  try {
    const response = await axiosInstance.put(`/auth/update/`, formData);

    return { success: true, data: response.data };
  } catch (err) {
    if (err.response) {
      console.log(err.response.data);
      return { success: false, data: null, error: err.response.data.error };
    } else {
      console.log(`Error: ${err.error}`);
      return { success: false, data: null, error: err.error };
    }
  }
};
  
export const getUserProfile = async () => {
  try {
    const response = await axiosInstance.get(`/auth/profile/`);

    return { success: true, data: response.data };
  } catch (err) {
    if (err.response) {
      return { success: false, data: null, error: err.response.data.error };
    } else {
      return { success: false, data: null, error: err.error };
    }
  }
};


//otp,user_id
export const verifyOTP = async (formData) => {
  try {
    const response = await axiosInstance.post("/auth/verify-password/", formData);

    return { success: true, data: response.data };
  } catch (err) {
    if (err.response) {
      console.log(err.response.data);
      return { success: false, data: null, error: err.response.data.error ?? err.response.data.detail };
    } else {
      console.log(`Error: ${err.message}`);
      return { success: false, data: null, error: err.message };
    }
  }
};

