
import React,{ useEffect,useState } from "react";
import { Button, Card, Col,Row,ListGroup } from '@themesberg/react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

import { useHistory } from "react-router-dom";
import { getDeliveries } from "../../services/delivery";


export const MerchantDeliveryCard = (props) => {

    const { _id,fullName, phoneNumber,deliveryType } = props;

        
  
    return (
      <Card border="light" className="shadow-sm">
        <Card.Body>
          <Row className="d-block d-xl-flex align-items-center">            
            <Col xs={12} xl={7} className="px-xl-0">
                <div>Driver Name: {fullName} </div> 
                <small>Phone Number {phoneNumber}</small> 
                <div>Vehicle Type {deliveryType}</div>              
            </Col>
            
          </Row>
        </Card.Body>
        <Card.Footer className="border-bottom border-light d-flex justify-content-between">
        <Button variant="success" size="sm" href={`tel:${phoneNumber}`}>
            <FontAwesomeIcon icon={faPhoneAlt}  className="me-2" /> Call Driver
        </Button>
      </Card.Footer>
      </Card>
    );
  };
 
export default () => {

    
    const navigate = useHistory();
    const [dataList, setDataList] = useState([]);
      

    useEffect(() => {
        getDeliveries().then((res) => {
            console.log(res.data);
            if (res.success && res.data) {
                setDataList(res.data);
            }
        });
    },[]);

     

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"></div>
            <Card border="light" className="shadow-sm">             
            <Card.Header className="border-bottom border-light d-flex justify-content-between">
                <h5 className="mb-0">Available Delivery Transports For your Orders</h5>                 
            </Card.Header>
            </Card>
            <ListGroup className="list-group-flush list my--3">
                <Row>
                {dataList.map(tm => <Col xs={12} sm={4} xl={3} className="mb-4">
                        <MerchantDeliveryCard  key={`sub-plans-${tm._id}`} {...tm} />
                    </Col>)}

                </Row>
            </ListGroup> 

        </>

    );
};
