
import React, { useEffect, useState } from "react";
import { faCartPlus, faCashRegister, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { Col, Row,} from '@themesberg/react-bootstrap';

import { CounterWidget,  TeamMembersWidget, ProgressTrackWidget} from "../../components/Widgets";
import { getReport } from "../../services/report";
import useAuth from "../../hook/useAuth";
import AuthContext from "../../context/AuthContext";

export default () => {

  const [reportData, setReportData] = useState({}); 
  const { role } = useAuth(AuthContext);
  useEffect(() => {
      getReport().then((res) => {
          console.log(res.data);
          if (res.success && res.data) {
            setReportData(res.data);
          }
      });
  },[]);
  return (
    <>
      
      <Row className="justify-content-md-center">
         
        {
          role == 'ROLE ADMIN' ?
              <>
              <Col xs={12} sm={6} xl={4} className="mb-4">
                  <CounterWidget
                    category="Suppliers"
                    title={reportData.supplier}
                    period="Feb 1 - Apr 1"
                    percentage={18.2}
                    icon={faChartLine}
                    iconColor="shape-secondary"
                  />
                </Col> 
            </>:<></>
          }

        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category="Items"
            title={reportData.product}
            period="Feb 1 - Apr 1"
            percentage={28.4}
            icon={faCashRegister}
            iconColor="shape-tertiary"
          />
        </Col>

        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
          category="Art Orders"
            title={reportData.order}
            data={400} 
            period="Feb 1 - Apr 1"
            percentage={28.4}
            icon={faCartPlus}
            iconColor="shape-tertiary"
            />
        </Col>
      </Row>

      {/* <Row>
        <Col xs={12} xl={12} className="mb-4">
          <Row>
            <Col xs={12} xl={12} className="mb-4">
              <Row>
               
                <Col xs={12} lg={4} className="mb-4">
                  <TeamMembersWidget />
                </Col>

                <Col xs={12} lg={8} className="mb-4">
                  <ProgressTrackWidget />
                </Col>
              </Row>
            </Col>

          </Row>
        </Col>
      </Row> */}
    </>
  );
};
