import React, { useState,useEffect } from "react";

import { Col, Row, Card, Form, Button } from '@themesberg/react-bootstrap';
import { editProduct, getProduct } from "../../services/products";

import AuthContext from "../../context/AuthContext";
import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { baseUrlFile } from "../../services/apiHelper";
import showAlertMessage from "../../components/AlertMessage";
import { ProgressContext } from "../../context/ProgressContext";

export default (props) => {

    const navigate = useHistory();
    const authCtx = useContext(AuthContext);
    const params = useParams();
    const id = params.id;
    const [product, setProduct] = useState({});
    const [mycategory, setMyCategory] = useState({});
    const [productImage,setImage] = useState({});
    const [categories,setCategory] = useState([]);
    
  const { setIsLoading } = React.useContext(ProgressContext);
    useEffect(() => {
        getProduct(id).then((res) => {
            console.log("GET PRODUCT:",res.data);
            console.log("GET PRODUCT Category:",res.data.categories);
            
            if (res.success && res.data) {
                setProduct(res.data.product); 
                setMyCategory(res.data.product.category);                 
                setImage(res.data.product.imageUrl);
                setCategory(res.data.categories);             
            }
        });
        
    }, []);


    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        const data = new FormData(event.currentTarget);

        var formData = new FormData();
        if(data.get('image') !== ""){
            formData.append('image', data.get('image'));
        }

        formData.append('name', data.get('name'));
        formData.append('description', data.get('description'));
        formData.append('price', data.get('price'));
        formData.append('category', data.get('category'));
        formData.append('isActive', true);


        editProduct(formData, id).then((res) => {
            setIsLoading(false);
            if (res.success && res.data) {
                navigate.push("/products");
                showAlertMessage(true,res.data.message);
            } else {
                showAlertMessage(false,res.error);
            }
        });

    };

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                
            </div>


            <Row>
                <Card border="light" className="bg-white shadow-sm mb-4">
                    <Card.Body>
                        {/* <h5 className="mb-4">Product Information Form</h5> */}
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={6} className="mb-3">
                                    <Form.Group id="name">
                                        <Form.Label>Product Name</Form.Label>
                                        <Form.Control required type="text" defaultValue={product.name} 
                                                    name="name" placeholder="Enter Product name" />
                                    </Form.Group>
                                </Col>
                                <Col md={6} className="mb-3">
                                <Form.Group id="category">
                                    <Form.Label>Product Category</Form.Label>
                                    <Form.Control as="select" name="category">
                                    {
                                        categories.map((category) =>{
                                        return (<option key={category._id} 
                                                        selected={mycategory._id === category._id ? true : false} 
                                                        value={category._id}>{category.name}</option>)
                                        })
                                    }
                                    
                                    </Form.Control>
                                </Form.Group>
                                </Col>
                             
                                
                                <Col md={6} className="mb-3">
                                <Form.Group id="image">
                                    <Form.Label>Product Image</Form.Label>
                                    <Form.Control name="image"  type="file" placeholder="Choose Image" />
                                </Form.Group>
                                
                                </Col>
                                <Col md={6} className="mb-3">
                                <img className="w-10" width={100} src={baseUrlFile + product.imageUrl} alt={product.name}  />
                                
                                </Col>
                          
                                <Col md={6} className="mb-3">
                                    <Form.Group id="phone">
                                        <Form.Label>Product Price in ETB</Form.Label>
                                        <Form.Control name="price" defaultValue={product.price} type="number" placeholder="Product price in ETB" />
                                    </Form.Group>
                                </Col>
                                 
                             
                                <Col md={12} className="mb-3">
                                <Form.Group id="description">
                                    <Form.Label>Product Description</Form.Label>
                                    <Form.Control name="description" defaultValue={product.description} 
                                    as="textarea" row={5} placeholder="Say something about your product " />
                                </Form.Group>
                                </Col>
                                
                            </Row> 
                            <div className="mt-3">
                                <Button variant="danger"  type="link" 
                                onClick={()=>navigate.push('/products')}>Cancel</Button> &nbsp;&nbsp;
                                <Button variant="primary" type="submit">Post Changes</Button>
                            </div>
                            </Form>
                    </Card.Body>
                </Card>
            </Row>
        </>
    );
};
