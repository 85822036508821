import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";
import { AlertContext } from '../context/AlertContext';

// pages

import DashboardOverview from "./dashboard/DashboardOverview";
import Transactions from "./Transactions";
import Settings from "./Settings";

import Signin from "./Login/index";
import Signup from "./SignUp/index";
import VerifyOtp from "./Verify/index";

import NotFoundPage from "../components/NotFound";
import ServerError from "../components/ServerError";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import Accordion from "./components/Accordion";
import Alerts from "./components/Alerts";
import Badges from "./components/Badges";
import Breadcrumbs from "./components/Breadcrumbs";
import Buttons from "./components/Buttons";
import Forms from "./components/Forms";
import Modals from "./components/Modals";
import Navs from "./components/Navs";
// import Navbars from "./components/Navbars";
import Pagination from "./components/Pagination";
import Popovers from "./components/Popovers";
import Progress from "./components/Progress";
import Tables from "./components/Tables";
import Tabs from "./components/Tabs";
import Tooltips from "./components/Tooltips";
import Toasts from "./components/Toasts";

import Products from './Products';
import CreateProduct from './Products/create';
import UpdateProduct from './Products/update';

import FeaturedProducts from './FeaturedProduct';
import CreateFeaturedProduct from './FeaturedProduct/create';
import UpdateFeaturedProduct from './FeaturedProduct/update';

import CategoryList from './Category/index';
import CreateCategory from './Category/create';
import UpdateCategory from './Category/update';

import SubPlanList from './SubPackage/index';
import CreateSubPlan from './SubPackage/create';
import UpdateSubPlan from './SubPackage/update';

import DeliveryList from './Delivery/index';
import MDeliveriyList from './Delivery/list'
import CreateDelivery from './Delivery/create';
import UpdateDelivery from './Delivery/update';

import Orders from './Orders/index';

import Subscription from './Subscription/index';

import useAuth from '../hook/useAuth';
import { AuthContextProvider } from '../context/AuthContext';
import { ProgressContext } from '../context/ProgressContext';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => (<> <Preloader show={loaded ? false : true} /> <Component {...props} /> </>)} />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const {isLoggedIn,} = useAuth();

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route {...rest} render={props => (
      isLoggedIn ? <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />

        <main className="content">
          <Navbar />
          <Component {...props} />
        </main>
      </>:<Redirect to={{ pathname: '/sign-in', state: { from: props.location } }} />
    )}
    />
  );
};

export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    color: '',
    open: false,
  });

  const handleClose = () => {
    setAlertMessage({
      open: false,
      severity: "",
      message: "",
    });
  };
 
  return (
    <AuthContextProvider>
      <AlertContext.Provider value={{ alertMessage, setAlertMessage }}>   
      <ProgressContext.Provider value={{ isLoading, setIsLoading}}>
      <Preloader show={isLoading ? true : false} />  
      <Switch>

        <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
        <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
        <RouteWithLoader exact path={Routes.Verify.path} component={VerifyOtp} />
        {/* <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
        <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
        <RouteWithLoader exact path={Routes.Lock.path} component={Lock} /> */}
        <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
        <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />

        {/* pages */}
        <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
        <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />
        <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
        {/* <RouteWithSidebar exact path={Routes.BootstrapTables.path} component={BootstrapTables} /> */}

        {/* components */}
        <RouteWithSidebar exact path={Routes.Accordions.path} component={Accordion} />
        <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />
        <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />
        <RouteWithSidebar exact path={Routes.Breadcrumbs.path} component={Breadcrumbs} />
        <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
        <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
        <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
        <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
        {/* <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} /> */}
        <RouteWithSidebar exact path={Routes.Pagination.path} component={Pagination} />
        <RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers} />
        <RouteWithSidebar exact path={Routes.Progress.path} component={Progress} />
        <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />
        <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
        <RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips} />
        <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} />

        {/* documentation */}
        {/* <RouteWithSidebar exact path={Routes.DocsOverview.path} component={DocsOverview} />
        <RouteWithSidebar exact path={Routes.DocsDownload.path} component={DocsDownload} />
        <RouteWithSidebar exact path={Routes.DocsQuickStart.path} component={DocsQuickStart} />
        <RouteWithSidebar exact path={Routes.DocsLicense.path} component={DocsLicense} />
        <RouteWithSidebar exact path={Routes.DocsFolderStructure.path} component={DocsFolderStructure} />
        <RouteWithSidebar exact path={Routes.DocsBuild.path} component={DocsBuild} /> */}
        {/* <RouteWithSidebar exact path={Routes.DocsChangelog.path} component={DocsChangelog} /> */}
        <RouteWithSidebar exact path={Routes.ProductList.path} component={Products} />
        <RouteWithSidebar exact path={Routes.CreateProduct.path} component={CreateProduct} />
        <RouteWithSidebar exact path={Routes.UpdateProduct.path} component={UpdateProduct} />
        <RouteWithSidebar exact path={Routes.CategoryList.path} component={CategoryList} />
        <RouteWithSidebar exact path={Routes.CreateCategory.path} component={CreateCategory} />
        <RouteWithSidebar exact path={Routes.UpdateCategory.path} component={UpdateCategory} />
        <RouteWithSidebar exact path={Routes.FeaturedProducts.path} component={FeaturedProducts} />
        <RouteWithSidebar exact path={Routes.CreateFeaturedProducts.path} component={CreateFeaturedProduct} />
        <RouteWithSidebar exact path={Routes.UpdateFeaturedProducts.path} component={UpdateFeaturedProduct} />
        <RouteWithSidebar exact path={Routes.ItemOrders.path} component={Orders} />

        <RouteWithSidebar exact path={Routes.SubPlanList.path} component={SubPlanList} />
        <RouteWithSidebar exact path={Routes.CreateSubPlan.path} component={CreateSubPlan} />
        <RouteWithSidebar exact path={Routes.UpdateSubPlan.path} component={UpdateSubPlan} />
        <RouteWithSidebar exact path={Routes.Subscription.path} component={Subscription} />

        <RouteWithSidebar exact path={Routes.DeliveryList.path} component={DeliveryList} />
        <RouteWithSidebar exact path={Routes.MDeliveries.path} component={MDeliveriyList} />
        <RouteWithSidebar exact path={Routes.CreateDelivery.path} component={CreateDelivery} />
        <RouteWithSidebar exact path={Routes.UpdateDelivery.path} component={UpdateDelivery} />
        
        <Redirect to={Routes.NotFound.path} />
      </Switch>
      </ProgressContext.Provider>  
    </AlertContext.Provider>
    </AuthContextProvider>
  )
};