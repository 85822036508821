import { useContext } from 'react'
import jwtDecode from 'jwt-decode'
import AuthContext from "../context/AuthContext";

const useAuth = () => {
    const authCtx = useContext(AuthContext);
    
    if (authCtx.token) {
        // console.log("Decoded..........Token...........: ",jwtDecode(authCtx.token))
        const {
            name,
            phoneNumber,
            role
        } = jwtDecode(authCtx.token);
        return {
            isLoggedIn: true,
            name,
            phoneNumber,
            role
        }
    }

    return {
        isLoggedIn: false,
        name: "",
        phoneNumber:"",
        role: ""
    }
}
export default useAuth