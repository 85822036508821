
import React from "react";

import { Button, Card, Table } from '@themesberg/react-bootstrap';
import { useEffect } from "react";
import { deleteProduct, getProducts } from "../../services/products";
import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEdit, faPlus,faTrash
} from '@fortawesome/free-solid-svg-icons';

import { Routes } from "../../routes";
import { useHistory } from "react-router-dom";
import { baseUrlFile } from "../../services/apiHelper";
import showAlertMessage from "../../components/AlertMessage";
import { ProgressContext } from "../../context/ProgressContext";

const TableRow = (props) => {

    const { _id, name, price,isActive, imageUrl } = props;

    return (
        <tr>
            <td>
                <Card.Link href="#" className="text-primary fw-bold">{_id}</Card.Link>
            </td>
            <td className="fw-bold">
                {name}
            </td>
            <td>ETB {price}</td>
            <td><img className="w-10" width={50} src={baseUrlFile + imageUrl} alt={name}  /></td>
            <td>
                {isActive ? "Yes":"No"}
            </td>
            <td>
            <Button variant="warning" size="sm" className="me-2" 
                    onClick={()=>props.navigate.push(`/product-update/${_id}`)} > 
                    <FontAwesomeIcon icon={faEdit}/>
                </Button>
                <Button variant="danger" size="sm"
                 onClick={()=>props.handleDelete(_id)}> <FontAwesomeIcon icon={faTrash}/></Button>
            </td>
        </tr>
    );
};

export default () => {
    const navigate = useHistory();
    const [productList, setProducts] = useState([]);

    const { setIsLoading } = React.useContext(ProgressContext);
    useEffect(() => {
        getProducts().then((res) => {
            console.log(res.data);
            if (res.success && res.data) {
                setProducts(res.data.products);
            }
        });
    },[]);


    const handleDelete = (id) => {
        if(window.confirm('Are you sure you want to delete this Product') == true){
            setIsLoading(true);
         deleteProduct(id).then((res) => {
            setIsLoading(false);
             if (res.success && res.data) {
                 showAlertMessage(true,"Product Data Deleted Successfully");
                 setProducts(
                    productList.filter((value) => {
                       return value._id !== id;
                     })
                   );
             } else {
                showAlertMessage(false,res.error);
             }
         });
        } 
 
     };


    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <Button onClick={()=>navigate.push(`${Routes.CreateProduct.path}`)}> 
                    <FontAwesomeIcon icon={faPlus}  className="me-2" />
                    <span>Add New</span>
                </Button>
                    
            </div>


            <Card border="light" className="shadow-sm mb-4">
                <Card.Body className="pb-0">
                    <Table responsive className="table-centered table-nowrap rounded mb-0">
                        <thead className="thead-light">
                            <tr>
                                <th className="border-0">#</th>
                                <th className="border-0">Name</th>
                                <th className="border-0">Price</th>
                                <th className="border-0">Image</th>
                                <th className="border-0">Is Actove</th>
                                <th className="border-0">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productList.map(pt => <TableRow key={`page-traffic-${pt._id}`} {...pt}  navigate={navigate} handleDelete={handleDelete}  />)}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            {/* </div> */}
        </>

    );
};
