import { axiosInstance} from "./apiHelper"; 


export const getOrders = async () => {
  try {
    const response = await axiosInstance.get(`/order/`);

    return { success: true, data: response.data };
  } catch (err) {
    if (err.response) {
      return { success: false, data: null, error: err.response.data.error };
    } else {
      return { success: false, data: null, error: err.error };
    }
  }
};
 

  export const getOrder = async (id) => {
    try {
      const response = await axiosInstance.get(`/order/${id}/`);
  
      return { success: true, data: response.data };
    } catch (err) {
      if (err.response) {
        return { success: false, data: null, error: err.response.data.error };
      } else {
        return { success: false, data: null, error: err.error };
      }
    }
  };  

  
  export const deleteOrder = async (id) => {
    try {
      const response = await axiosInstance.delete(`/order/delete/${id}`);
  
      return { success: true, data: response.data };
    } catch (err) {
      if (err.response) {
        return { success: false, data: null, error: err.response.data.error };
      } else {
        return { success: false, data: null, error: err.error };
      }
    }
  };
  