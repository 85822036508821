import axios from "axios";

const baseUrl = "https://clickart-api.sparktechnologygroups.com/api";
const baseUrlFile = "https://clickart-api.sparktechnologygroups.com/";

// const baseUrl = "http://localhost:4000/api";
// const baseUrlFile = "http://localhost:4000/";

const axiosInstance = axios.create({
  baseURL:baseUrl,
  headers:{
    "Content-Type":"application/json",
    "Accept": "application/json"
  }
});

axiosInstance.interceptors.request.use(function (config) {

  const token = localStorage.getItem("accessToken");
  config.headers.Authorization = token ? `${token}` : "";
  return config;
});

export  {axiosInstance,baseUrl,baseUrlFile};
