
import React,{ useEffect,useState } from "react";

import { Button, Card, Table } from '@themesberg/react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEdit, faPlus,faTrash
} from '@fortawesome/free-solid-svg-icons';

import { Routes } from "../../routes";
import { useHistory } from "react-router-dom";
import { getDeliveries,deleteDelivery } from "../../services/delivery";
import showAlertMessage from "../../components/AlertMessage";
import { ProgressContext } from "../../context/ProgressContext";

const TableRow = (props) => {

    const { _id,fullName, phoneNumber,deliveryType } = props;

    return (
        <tr>
            <td>
                <Card.Link href="#" className="text-primary fw-bold">{_id}</Card.Link>
            </td>
            <td>
                {fullName}
            </td>
            <td>{phoneNumber}</td>
            <td className="fw-bold">
                {deliveryType}
            </td>
            <td>
            <Button variant="warning" size="sm" className="me-2" 
                    onClick={()=>props.navigate.push(`/update-delivery/${_id}`)} > 
                    <FontAwesomeIcon icon={faEdit}/>
                </Button>
                <Button variant="danger" size="sm"
                 onClick={()=>props.handleDelete(_id)}> <FontAwesomeIcon icon={faTrash}/></Button>
            </td>
        </tr>
    );
};

export default () => {
    const navigate = useHistory();
    const [dataList, setDataList] = useState([]);

    const { setIsLoading } = React.useContext(ProgressContext);
    useEffect(() => {
        getDeliveries().then((res) => {
            console.log(res.data);
            if (res.success && res.data) {
                setDataList(res.data);
            }
        });
    },[]);


    const handleDelete = (id) => {
        if(window.confirm('Are you sure you want to delete this Delivery') == true){
            setIsLoading(true);
         deleteDelivery(id).then((res) => {
            setIsLoading(false);
             if (res.success && res.data) {
                 showAlertMessage(true,"Delivery Data Deleted Successfully");
                 setDataList(
                    dataList.filter((value) => {
                       return value._id !== id;
                     })
                   );
             } else {
                showAlertMessage(false,res.error);
             }
         });
        } 
 
     };


    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <Button onClick={()=>navigate.push(`${Routes.CreateDelivery.path}`)}> 
                    <FontAwesomeIcon icon={faPlus}  className="me-2" />
                    <span>Add New</span>
                </Button>
                    
            </div>


            <Card border="light" className="shadow-sm mb-4">
                <Card.Body className="pb-0">
                    <Table responsive className="table-centered table-nowrap rounded mb-0">
                        <thead className="thead-light">
                            <tr>
                                <th className="border-0">#</th>
                                <th className="border-0">Driver Name</th>
                                <th className="border-0">Phone Number</th>
                                <th className="border-0">Vehicle Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataList.map(pt => <TableRow key={`page-traffic-${pt._id}`} {...pt}  navigate={navigate} handleDelete={handleDelete}  />)}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            {/* </div> */}
        </>

    );
};
