import React, { useEffect } from "react";

import { Col, Row, Card, Form, Button } from '@themesberg/react-bootstrap';

import { useHistory, useParams } from "react-router-dom";
import { baseUrlFile } from "../../services/apiHelper";
import showAlertMessage from "../../components/AlertMessage";
import { ProgressContext } from "../../context/ProgressContext";
import { editCategory, getCategory } from "../../services/category";

export default () => {
  const navigate = useHistory();

  const { setIsLoading } = React.useContext(ProgressContext);
  const params = useParams();
  const id = params.id;

  const [category, setCategory] = React.useState({});
  const [selectedProducts,setSelectedProducts] = React.useState([]);

    useEffect(()=>{
        getCategory(id).then((res)=>{          
            if(res.success && res.data){
                setCategory(res.data.category)
                setSelectedProducts(res.data.category.products);
            }
          });      
    },[]);


    const handleChange = (e) => {
      var options = e.target.options;
      var value = [];
      for (var i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
      setSelectedProducts(value);
    }

    const handleSubmit = (event) => {
      event.preventDefault();
      setIsLoading(true);
      const data = new FormData(event.currentTarget);

      var formData = new FormData();

      if (data.get('image') !== "") {
        formData.append('image', data.get('image'));
      }
      
      
      formData.append('name', data.get('name'));
      formData.append('description', data.get('description'));
      formData.append('name_am', data.get('name_am'));
      formData.append('description_am', data.get('description_am'));
      formData.append('isActive', data.get('isActive') === 'on' ? true : false);
      
      // {'name':data.get('name'),
      //               'description':data.get('description'),
      //               'isActive':data.get('isActive') === 'on' ? true : false}
      // const data = new FormData(event.currentTarget);        
        editCategory(formData,id).then((res) => {
          setIsLoading(false);
          if (res.success && res.data) {                 
              navigate.push("/product-categories");
              showAlertMessage(true, res.data.message);              
          } else {
            showAlertMessage(false,res.error);
          }
        }); 
    
    };

  return (
    <>      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        
    </div>
      <Row>
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Update Product Category Information Form</h5>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="name">
                <Form.Label>Category Name</Form.Label>
                <Form.Control required type="text" defaultValue={category.name}
                     name="name" placeholder="Enter Category name" />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="name">
                <Form.Label>Category Name Amharic</Form.Label>
                <Form.Control required type="text" defaultValue={category.name_am}
                     name="name_am" placeholder="Enter Category Name Amharic" />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="image">
                <Form.Label>Category Image</Form.Label>
                <Form.Control name="image" type="file" placeholder="Choose Image" />
              </Form.Group>
            </Col>
            <Col md={3} className="mb-3">
              <br/> 
            <Form.Group id="active">
              <Form.Check // prettier-ignore
                type="switch"
                name="isActive"
                id="isActive"
                defaultChecked={category.isActive}
                label="Category is Active or Not ?"
              /> 
              </Form.Group>
            </Col>
            <Col md={3} className="mb-3">      
            <br/>        
              <img className="w-10" width={100} src={baseUrlFile + category.imageUrl} alt={category.name}  />
            </Col>
          </Row>
          <Row>
          
            
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Category Description</Form.Label>
                <Form.Control name="description" as="textarea" required 
                defaultValue={category.description}
                row={5} placeholder="Category Description" />
              </Form.Group>
            </Col>

            <Col md={6} className="mb-3">
              <Form.Group id="description_am">
                <Form.Label>Category Description Amharic</Form.Label>
                <Form.Control name="description_am" as="textarea" required 
                defaultValue={category.description_am}
                row={5} placeholder="Category Description Amharic" />
              </Form.Group>
            </Col>
             
          </Row> 
          <div className="mt-3">
          <Button variant="danger"  type="link" 
                                onClick={()=>navigate.push('/product-categories')}>Cancel</Button> &nbsp;&nbsp;
            <Button variant="primary" type="submit">Save Changes</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
      </Row>
    </>
  );
};
