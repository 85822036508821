
import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { faShopware } from "@fortawesome/free-brands-svg-icons";
import { createAccount } from "../../services/auth";
import { AlertContext } from "../../context/AlertContext";
import showAlertMessage from "../../components/AlertMessage";

export default () => {
  const navigate = useHistory();
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if(data.get('phoneNumber') !== "" && data.get('name') !== "" && data.get('merchant_type') !== "" && data.get('location') !== ""){

      createAccount(data).then((res) => {
        if (res.success && res.data) {   
            navigate.push(`/verify-otp/${res.data.data.user_id}`);
            showAlertMessage(true,res.data.message);  
          
        } else {
          console.log(res.data,'error')
          showAlertMessage(false,res.error);
        }
      }); 
  
    }else{
      showAlertMessage(false, "Please fill out all required input fields");
    }
  };
  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          {/* <p className="text-center">
            <Card.Link as={Link} to={Routes.DashboardOverview.path} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
            </Card.Link>
          </p> */}
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-2 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Create a Supplier account</h3>
                </div>
                <Form className="mt-4" onSubmit={handleSubmit}>
                  <Form.Group id="name" className="mb-4">
                    <Form.Label>Name (Company/Shop)</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faShopware} />
                      </InputGroup.Text>
                      <Form.Control required type="text" name="name" placeholder="Name (Company/Shop)" />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="phoneNumber" className="mb-4">
                    <Form.Label> Phone Number</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faPhoneAlt} />
                      </InputGroup.Text>
                      <Form.Control required type="text" name="phoneNumber" placeholder="0912345678" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="merchant_type">
                    <Form.Label>Company Type</Form.Label>
                    <Form.Select name="merchant_type">
                      {
                        ["Company","Individual"].map((item) =>{
                          return (<option key={item} value={item}>{item}</option>)
                        })
                      }
                      
                    </Form.Select>
                  </Form.Group>

                  <Form.Group id="location">
                    <Form.Label>Location</Form.Label>
                    <Form.Select name="location">
                      {
                        [
                          "Afar", 
                          "Amhara", 
                          "Oromia", 
                          "Somali", 
                          "Benishangul-Gumuz", 
                          "Gambella", 
                          "Sidama", 
                          "Tigray", 
                          "SNNPR", 
                          "South West Ethiopia Peoples", 
                          "Harari",
                          "Addis Ababa",
                          "Dire Dawa" 
                        ].map((item) =>{
                          return (<option key={item} value={item}>{item}</option>)
                        })
                      }                      
                    </Form.Select>
                  </Form.Group>

                  
                  <FormCheck type="checkbox" className="d-flex mb-4 mt-4">
                    <FormCheck.Input required id="terms" className="me-2" />
                    <FormCheck.Label htmlFor="terms">
                      I agree to the <Card.Link>terms and conditions</Card.Link>
                    </FormCheck.Label>
                  </FormCheck>

                  <Button variant="primary" type="submit" className="w-100">
                    Sign up
                  </Button>
                </Form>
 
                 
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Already have an account?
                    <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                      {` Login here `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
