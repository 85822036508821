import { axiosInstance} from "./apiHelper"; 
 
export const getPackages = async () => {
  try {
    const response = await axiosInstance.get(`/sub-package/`);

    return { success: true, data: response.data };
  } catch (err) {
    if (err.response) {
      return { success: false, data: null, error: err.response.data.error };
    } else {
      return { success: false, data: null, error: err.error };
    }
  }
};
  

export const createPackage = async (formData) => {
  try {
    const response = await axiosInstance.post(`/sub-package/create/`,formData);

    return { success: true, data: response.data };
  } catch (err) {
    if (err.response) {
      return { success: false, data: null, error: err.response.data.error };
    } else {
      return { success: false, data: null, error: err.error };
    }
  }
};


export const getPackage = async (id) => {
  try {
    const response = await axiosInstance.get(`/sub-package/${id}/`);

    return { success: true, data: response.data };
  } catch (err) {
    if (err.response) {
      return { success: false, data: null, error: err.response.data.error };
    } else {
      return { success: false, data: null, error: err.error };
    }
  }
};

export const editPackage = async (formData,id) => {
  try {
    const response = await axiosInstance.put(`/sub-package/${id}/`,formData);

    return { success: true, data: response.data };
  } catch (err) {
    if (err.response) {
      return { success: false, data: null, error: err.response.data.error };
    } else {
      return { success: false, data: null, error: err.error };
    }
  }
};


export const deletePackage = async (id) => {
  try {
    const response = await axiosInstance.delete(`/sub-package/delete/${id}/`);

    return { success: true, data: response.data };
  } catch (err) {
    if (err.response) {
      return { success: false, data: null, error: err.response.data.error };
    } else {
      return { success: false, data: null, error: err.error };
    }
  }
};